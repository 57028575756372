import { render, staticRenderFns } from "./ProductPreview.vue?vue&type=template&id=6902303e&"
import script from "./ProductPreview.vue?vue&type=script&lang=js&"
export * from "./ProductPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductImageGallery: require('/~/src/modules/opus-ecom/components/Catalog/ProductImageGallery.vue').default,OpusTitle: require('/~/src/modules/opus/components/Title.vue').default,HanselExternalNotification: require('/~/src/modules/opus-ecom/components/HanselExternalNotification.vue').default,TierPrice: require('/~/src/components/TierPrice.vue').default,AddToCart: require('/~/src/modules/opus-ecom/components/Catalog/AddToCart.vue').default,ProductAttributes: require('/~/src/components/ProductAttributes.vue').default,OpusAccordionItem: require('/~/src/modules/opus/components/AccordionItem.vue').default,OpusAccordion: require('/~/src/modules/opus/components/Accordion.vue').default,ProductPageSkeleton: require('/~/src/modules/opus-ecom/components/ProductPageSkeleton.vue').default})
